import React from 'react';
import { useNavigate } from 'react-router-dom';
import backgroundImage from '../assets/BG_thankyou_page.webp';
import { useTranslation } from 'react-i18next';

const WelcomePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center p-4"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'auto 100%', 
        backgroundPosition: 'center', 
        backgroundRepeat: 'no-repeat', 
        backgroundAttachment: 'fixed', 
      }}
    >
      <div className="text-center bg-white bg-opacity-0 p-12 rounded-lg max-w-4xl w-full space-y-8">
        <h1 className="text-5xl font-bold mb-6">{t("WELCOME TO ARCSEED")}</h1> 
        <div className="w-full h-px bg-black"></div>
        <p className="text-2xl mb-12">{t("WATCH THE VIDEO BELOW OR CLICK THE LOGIN BUTTON TO START")}</p> 
        
        <div className="w-full" style={{ paddingBottom: '56.25%', position: 'relative' }}> 
          <iframe
            className="absolute top-0 left-0 w-full h-full"
            src="https://www.youtube.com/embed/V83nGOW3BoM"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <button
          onClick={() => navigate('/Login')}
          className="px-12 py-4 md:px-18 md:py-6 border-2 border-black text-black text-2xl md:text-3xl font-bold hover:bg-blue-100 transition-colors"
        >
          LOGIN
        </button> 
      </div>
    </div>
  );
};

export default WelcomePage;
