import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import "./App.css";
import { Trans, useTranslation } from "react-i18next";
import { IoIosArrowBack } from "react-icons/io";
import { add_Back } from "../../address";
import NewPopUpComponent from "../../components/Generic/NewPopUpComponent";
import { Context } from "../../App";
import { useContext } from "react";

export default function Register() {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOutsideClick = (e) => {
    if (isMenuOpen && !e.target.closest(".menu-container")) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isMenuOpen]);

  const [formData, setFormData] = useState({
    nome: "",
    nick_name: "",
    email: "",
    senha: "",
    confirmasenha: "",
    telefone: "",
    usuario_de_criacao: "",
    autenticacao_dois_fatores: false,
  });

  const [nomeError, setNomeError] = useState("");
  const [emailError] = useState("");
  const [telefoneError, setTelefoneError] = useState("");
  const [senhaError, setSenhaError] = useState("");
  const [confirmasenhaError, setConfirmaSenhaError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState('Weak');
  const [strengthLevel, setStrengthLevel] = useState(0);


  const handleInputChangeNome = (e) => {
    const { value } = e.target;
    const newValue = value.replace(/[^a-zA-Zà-úÀ-Ú ]/g, "").slice(0, 50);
    setFormData((prevData) => ({ ...prevData, nome: newValue }));
  };

  const handleInputChangeEmail = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z0-9@._-]*$/.test(value) && value.length <= 40) {
      setFormData((prevData) => ({
        ...prevData,
        email: value,
      }));
    }
  };

  const handleInputChangeTelefone = (e) => {
    const value = e.target.value;
    const newValue = value.replace(/[^0-9]/g, ""); // Remove caracteres não numéricos
    if (newValue.length <= 15) {
      setFormData((prevData) => ({
        ...prevData,
        telefone: newValue,
      }));
    }
  };

  const handleInputChangeSenha = (e) => {
    const { value } = e.target;
    const trimmedValue = value.slice(0, 255);
    
    setFormData((prevData) => ({
      ...prevData,
      senha: trimmedValue,
    }));
    
    const strengthText = assessPasswordStrength(trimmedValue);
    setPasswordStrength(strengthText);
    setIsPasswordValid(validatePassword(trimmedValue));
  };
  
  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  
    return (
      password.length >= minLength &&
      hasLowerCase &&
      hasNumber
    );
  };
  
  const assessPasswordStrength = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  
    let strengthLevel = 0; // 0: Too Short, 1: Weak, 2: Medium, 3: Strong
  
    if (password.length >= minLength) {
      if (hasUpperCase) strengthLevel++;
      if (hasLowerCase) strengthLevel++;
      if (hasNumber) strengthLevel++;
      if (hasSpecialChar) strengthLevel++;
    }

      // Update the strength level and strength text based on the level
  if (strengthLevel === 0) {
    setPasswordStrength("Too Short");
  } else if (strengthLevel === 1) {
    setPasswordStrength("Weak");
  } else if (strengthLevel === 2) {
    setPasswordStrength("Medium");
  } else if (strengthLevel === 3) {
    setPasswordStrength("Strong");
  }

  // Update the state for the strength level
  setStrengthLevel(strengthLevel);
  
    switch (strengthLevel) {
      case 0:
        return 'Too Short'; // Text for strength 0
      case 1:
        return 'Weak'; // Text for strength 1
      case 2:
        return 'Medium'; // Text for strength 2
      case 3:
        return 'Strong'; // Text for strength 3
      default:
        return 'Very Strong'; // Fallback text
    }
  };
  
  const handleInputChangeConfirmaSenha = (e) => {
    const { value } = e.target;
    const trimmedValue = value.slice(0, 255);
    setFormData((prevData) => ({
      ...prevData,
      confirmasenha: trimmedValue,
    }));
  };
  
  const getPasswordStrengthColor = (strengthText) => {
    switch (strengthText) {
      case 'Too Short':
        return '#808080'; // Gray for too short
      case 'Weak':
        return '#FF0000'; // Red for weak
      case 'Medium':
        return '#CBBA00'; // Yellow for medium
      case 'Strong':
        return '#19AC43'; // Green for strong
      default:
        return '#386EDA'; // Fallback color
    }
  };


  

  const handleInputChangeNickName = (e) => {
    const value = e.target.value;
    const trimmedValue = value.replace(/[^a-zA-Z0-9@._-]/g, "").slice(0, 30);
    setFormData((prevData) => ({
      ...prevData,
      nick_name: trimmedValue,
    }));
  };

  const handleChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      autenticacao_dois_fatores: event.target.checked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (
      formData.nome === "" ||
      formData.nick_name === "" ||
      formData.telefone === "" ||
      formData.email === "" ||
      formData.senha === "" ||
      formData.confirmasenha === ""
    ) {
      setWarningMessage("There are empty fields");
      setIsSubmitting(false);
      return;
    }
    if (!/^\d*$/.test(formData.telefone)) {
      setWarningMessage("The phone field have to contain only numbers");
      setIsSubmitting(false);
      return;
    }

    if (!/^[a-zA-Z\s]+$/.test(formData.nome)) {
      setWarningMessage("The name field should only contain letters.");
      setIsSubmitting(false);
      return;
    }

    if (formData.senha !== formData.confirmasenha) {
      setWarningMessage("The passwords aren't the same");
      setIsSubmitting(false);
      return;
    }

    // Validação final da senha
    if (!validatePassword(formData.senha)) {
      setWarningMessage(
        "A senha deve ter pelo menos 8 caracterese e ao menos um número."
      );
      setIsSubmitting(false);
      return;
    }

    const {
      nome,
      nick_name,
      email,
      senha,
      telefone,
      autenticacao_dois_fatores,
      usuario_de_criacao,
    } = formData;

    try {
      const response = await fetch(`${add_Back}/registro`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nome,
          nick_name,
          email,
          senha,
          telefone,
          usuario_de_criacao,
          autenticacao_dois_fatores,
        }),
      });
      const data = await response.json();

      if (response.ok) {
        window.location.href = "/ThanksPageRegister";
      } else {
        setWarningMessage(
          data.error ||
            "Erro interno do servidor. Por favor, tente novamente mais tarde."
        );
        console.error(
          "Erro ao registrar usuário:",
          data.details || "Unknown error"
        );
      }
    } catch (error) {
      setWarningMessage(
        "Erro interno do servidor. Por favor, tente novamente mais tarde."
      );
      console.error("Erro ao enviar dados para o backend:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    document.title = "Register - Arcseed AI";
    return () => {
      document.title = "Arcseed AI";
    };
  }, []);

  const [warningMessage, setWarningMessage] = useContext(Context);



  return (
    <section className="min-h-screen max-w-screen bg-black flex flex-col relative">
      <NewPopUpComponent isClosable={true} />
      <Link
        className="flex w-screen px-[4%] pt-[4%] lg:pt-0 lg:px-0 lg:w-fit lg:absolute top-[4%] left-[4%]"
        to="/"
      >
        {/* Usando o ícone para voltar à página inicial */}
        <button className="rounded-full bg-gray-900 text-white p-2">
          <IoIosArrowBack size={24} />
        </button>
      </Link>
      <div className="flex items-center justify-center py-[5%] md:h-auto">
        <div className="relative flex flex-col min-w-0 break-words w-[90%] md:w-[45%] lg:w-[40%] shadow-lg  bg-zinc-100 border-0">
          <div className="flex-auto px-6 lg:px-10 p-6">
            <div className="text-blueGray-400 font-title text-xl text-center mb-3 font-bold">
              <small>{t("Sign up with credentials")}</small>
            </div>
            <form
              onSubmit={handleSubmit}
              className="text-left font-paragraph text-xl"
            >
              <div className="relative w-full mb-3">
                <label className="relative w-full mb-3" htmlFor="grid-password">
                  {t("Name")}
                </label>
                <input
                  type="text"
                  name="nome"
                  value={formData.nome}
                  onChange={handleInputChangeNome}
                  className="border-0 p-2 text-md font-paragraph placeholder-blueGray-300 text-blueGray-600 bg-white rounded shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder={t("Name")}
                />
                <span className="text-red-500">{nomeError}</span>
              </div>
              <div className="relative w-full mb-3">
                <label className="relative w-full mb-3" htmlFor="grid-password">
                  {t("Username")}
                </label>
                <input
                  type="text"
                  name="nick_name"
                  value={formData.nick_name}
                  onChange={handleInputChangeNickName}
                  className="border-0  p-2 font-paragraph placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder={t("Username")}
                />
              </div>
              <div className="relative w-full mb-3">
                <label className="relative w-full mb-3" htmlFor="grid-password">
                  {t("Phone")}
                </label>
                <input
                  type="text"
                  name="telefone"
                  value={formData.telefone}
                  onChange={handleInputChangeTelefone}
                  className="border-0  p-2 font-paragraph placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder={t("Phone")}
                />
                <span className="text-red-500">{telefoneError}</span>
              </div>

              <div className="relative w-full mb-3">
                <label className="relative w-full mb-3" htmlFor="grid-password">
                  E-mail
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChangeEmail}
                  className="border-0  p-2 font-paragraph placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="E-mail"
                />
                <span className="text-red-500">{emailError}</span>
              </div>

              <div className="relative w-full mb-3">
                <label className="relative w-full mb-3" htmlFor="grid-password">
                  {t("Password")}
                </label>
                <input
                  type="password"
                  name="senha"
                  value={formData.senha}
                  onChange={handleInputChangeSenha}
                  className="border-0 p-2 font-paragraph placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder={t("Password")}
                />
                <span className="text-red-500">{senhaError}</span>
  
                {/* Indicador de força da senha */}
                <div
                  className="h-1 w-full rounded"
                  style={{
                    backgroundColor: getPasswordStrengthColor(passwordStrength),
                    width: `${(strengthLevel / 4) * 100}%`, // Forças respectivas
                  }}
                />
                <div className="mt-1">
                  <strong>{t("Password Strength:")} </strong>
                  <strong style={{ color: getPasswordStrengthColor(passwordStrength) }}>
                    {passwordStrength}
                  </strong>
                </div>
              </div>
              <div className="relative w-full mb-3">
                <label className="relative w-full mb-3" htmlFor="grid-password">
                  {t("Confirm Password")}
                </label>
                <input
                  type="password"
                  name="confirmasenha"
                  value={formData.confirmasenha}
                  onChange={handleInputChangeConfirmaSenha}
                  className="border-0  p-2 font-paragraph text-md placeholder-blueGray-300 text-blueGray-600 bg-white rounded shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder={t("Password")}
                />
                <span className="text-red-500">{confirmasenhaError}</span>
              </div>

              <div className="text-center items-align-center">
                <label className="inline-flex cursor-pointer">
                  <input
                    id="customCheckLogin"
                    type="checkbox"
                    name="autenticacao_dois_fatores"
                    checked={formData.autenticacao_dois_fatores}
                    onChange={handleChange}
                    className="form-checkbox border-0 rounded text-blueGray-700 mr-2 w-5 ease-linear transition-all duration-150"
                    required
                  />
                  <span className="ml-2 text-md font-bold text-blueGray-600">
                    {t("I agree with the ")}
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to="/Privacy"
                      className="text-lightBlue-500 z-10 hover:underline"
                    >
                      {t("Privacy Policy")}
                    </Link>
                  </span>
                </label>
              </div>

              <div className="text-center mt-6">
                <button
                  className="bg-slate-300 font-title bg-opacity-70 hover:bg-opacity-100 text-black active:bg-blueGray-600 text-md font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? t("Submitting...") : t("Create Account")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
